<template>
  <div class="vx-row mb-12">
    <div class="vx-col mb-15 w-full space-y-1">
      <div class="flex flex-row w-1/2">
        <div class="w-1/2">
          <span class="text-lg">Delivery Date</span>
        </div>
        <div class="w-full">
          <date-range-picker
            style="min-height: 40px"
            class="w-full"
            ref="picker"
            opens="center"
            :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
            :singleDatePicker="false"
            :timePicker="false"
            :timePicker24Hour="false"
            :showWeekNumbers="false"
            :showDropdowns="false"
            :autoApply="true"
            v-model="date"
            :linkedCalendars="true"
          >
            <template v-slot:input="picker" style="min-width: 350px">
              {{ datePickerDateFormat(picker.startDate) }} -
              {{ datePickerDateFormat(picker.endDate) }}
            </template>
          </date-range-picker>
        </div>
      </div>
      <div class="flex flex-row w-1/2">
        <div class="w-1/2">
          <span class="text-lg">Vehicle Number </span>
        </div>
        <div class="w-full">
          <multiselect
            class="selectExample"
            v-model="vehicles"
            :options="optionalVehicle"
            :multiple="true"
            :allow-empty="true"
            :group-select="false"
            :max-height="100"
            :limit="3"
            track-by="vehicle_number"
            placeholder="Type to search"
            :searchable="true"
            label="vehicle_number"
            name="vehicle_number"
            @search-change="handleSearchVehicle"
          />
          <!-- <span v-show="errors.has('vehicle')" class="text-danger"
            >Vehicle is required</span
          > -->
        </div>
      </div>
      <div class="flex flex-row w-1/2">
        <div class="w-1/2">
          <span class="text-lg">Driver Name </span>
        </div>

        <div class="w-full">
          <multiselect
            class="selectExample"
            v-model="drivers"
            :options="optionalDriver"
            :multiple="true"
            :allow-empty="true"
            :group-select="false"
            :max-height="100"
            :limit="3"
            track-by="driver_name"
            placeholder="Type to search"
            :searchable="true"
            label="driver_name"
            name="driver_name"
            @search-change="handleSearchDriver"
          />
          <!-- <span v-show="errors.has('driver_name')" class="text-danger"
            >Driver is required</span
          > -->
        </div>
      </div>

      <div class="vx-col w-1/2 mt-8 flex justify-end space-x-1">
        <vs-button
          @click="onClickBtnFilter"
          size="medium"
          color="primary"
          icon-pack="feather"
          icon="icon-search"
          title="Search"
        >
          Filter</vs-button
        >
        <vs-button
          @click="onClickBtnReset"
          size="medium"
          color="warning"
          icon-pack="feather"
          icon="icon-rotate-ccw"
          title="Reset"
        >
          Reset</vs-button
        >
      </div>
    </div>
    <div v-bind:class="[detail ? detailShow : '', detailHide]">
      <vs-table
        search
        stripe
        border
        descriptions
        :sst="true"
        :data="table.data"
        :max-items="table.length"
        :total="table.total"
        @change-page="handleChangePage"
        @search="handleSearch"
        @sort="handleSort"
      >
        <template slot="header">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div
              class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
            >
              <span class="mr-2"
                >{{ this.table.start }} - {{ this.table.end }} of
                {{ this.table.total }}</span
              >
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item
                v-for="item in table.limits"
                :key="item"
                @click="handleChangelength(item)"
              >
                <span>{{ item }}</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </template>
        <template slot="thead">
          <vs-th sort-key="">Action</vs-th>
          <vs-th sort-key="shipment_number">Shipment Document</vs-th>
          <vs-th sort-key="vehicle_number">Vehicle ID</vs-th>
          <vs-th sort-key="driver_name">Driver Name</vs-th>
          <vs-th sort-key="delivery_plan_date_str">Delivery Date</vs-th>
          <vs-th sort-key="count_delivery_order"># of DO</vs-th>
          <vs-th sort-key="total_weight">Total Weigh (Kg)</vs-th>
          <vs-th sort-key="total_volume">Total Volume (m3)</vs-th>
          <vs-th sort-key="status">Status</vs-th>
        </template>
        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>
              <div class="vx-input-group flex">
                <!-- <vs-checkbox
                  v-model="cartDeliveryPlan"
                  :vs-value="tr.id"
                  :key="tr.indextr"
                  color="success"
                  @input="handleClickCheckBox"
                /> -->
                <vs-button
                  size="small"
                  color="danger"
                  icon-pack="feather"
                  icon="icon-printer"
                  title="Print"
                  style="margin-right: 5px"
                  @click="HandlePrintPdf(tr)"
                />
                <vs-button
                  size="small"
                  color="warning"
                  icon-pack="feather"
                  icon="icon-eye"
                  style="margin-right: 5px"
                  title="Show"
                  @click="viewDetail(tr.transport_plan_lines)"
                />
              </div>
            </vs-td>

            <vs-td :data="data[indextr].shipment_number">
              {{ data[indextr].shipment_number }}
            </vs-td>
            <vs-td :data="data[indextr].delivery_plan_date_str">
              <ul>
                <li>
                  <strong>Vehicle:</strong>
                  {{ data[indextr].vehicle_number }}
                </li>
                <li
                  v-if="data[indextr].vehicle_childs[0].transport_plan_id != 0"
                >
                  <strong>Additional Vehicle:</strong>
                  <ul>
                    <li
                      :data="tr"
                      :key="indextr"
                      v-for="(tr, indextr) in data[indextr].vehicle_childs"
                    >
                      {{ tr.vehicle_number }}
                    </li>
                  </ul>
                </li>
              </ul>
            </vs-td>
            <vs-td :data="data[indextr].driver_name">
              <ul>
                <li>
                  <strong>{{ data[indextr].driver_name }}</strong>
                </li>
                <li
                  v-if="data[indextr].vehicle_childs[0].transport_plan_id != 0"
                >
                  <strong>Additional Driver:</strong>
                  <ul>
                    <li
                      :data="tr"
                      :key="indextr"
                      v-for="(tr, indextr) in data[indextr].vehicle_childs"
                    >
                      {{ tr.driver_name }}
                    </li>
                  </ul>
                </li>
              </ul>
            </vs-td>
            <vs-td :data="data[indextr].delivery_plan_date_str">
              {{ data[indextr].delivery_plan_date_str }}
            </vs-td>
            <vs-td :data="data[indextr].count_delivery_order">
              {{ data[indextr].count_delivery_order }}
            </vs-td>
            <vs-td :data="data[indextr].total_weight">
              {{ data[indextr].total_weight }}
            </vs-td>
            <vs-td :data="data[indextr].total_volume">
              {{ data[indextr].total_volume }}
            </vs-td>
            <vs-td :data="data[indextr].status">
              {{ data[indextr].status }}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <vs-pagination
        style="padding-top: 5px"
        :total="table.totalPage"
        v-model="setPage"
      />
      <div class="vx-row">
        <div class="vx-col sm:w-6/12 w-full">
          <!-- <vs-button
            class="mr-3 mb-2"
            v-on:click="ExportForm()"
            v-if="table.data.length > 0"
            >Print</vs-button
          > -->
          <!-- <vs-button
            class="mr-3 mb-2"
            v-on:click="ReturnTransportPlan()"
            v-if="table.data.length > 0"
            >Return</vs-button
          > -->
        </div>
      </div>
    </div>
    <!--view detail ----->
    <transition name="detail-fade">
      <div
        v-if="detail"
        v-bind:class="[detail ? detailShowV2 : '', detailHide]"
      >
        <vs-row>
          <vs-col
            style="padding-bottom: 8px"
            vs-offset="8"
            vs-type="flex"
            vs-justify="rigth"
            vs-align="rigth"
            vs-w="4"
          >
            <vs-button
              class="ml-auto"
              size="small"
              v-on:click="handleClose"
              color="grey"
              icon-pack="feather"
              icon="icon-x-square"
              >Close</vs-button
            >
          </vs-col>

          <!-- form component -->
          <Table2
            @close="handleClose"
            :dataViews="dataViews"
            @sendData="DataCheck($event)"
          ></Table2>
        </vs-row>
      </div>
    </transition>
  </div>
</template>
<script>
import moment from "moment";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import Table2 from "../tableView.vue";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
export default {
  components: {
    Table2,
    DateRangePicker,
  },
  mounted() {
    // this.getData();
    this.getDriver();
    this.getVehicle();
  },

  data() {
    return {
      deleteId: null,
      table: this.tableDefaultState(),
      detailShow: "core vx-col md:w-1/4 w-full mb-base ",
      detailShowV2: "core vx-col md:w-3/4 w-full mb-base ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      showingData: false,
      selectAll: false,
      cartDeliveryPlan: [],
      indexAddDO: null,
      allCheck: false,
      selected: this.$store.state.tms.tms.selected,
      detail: false,
      dataViews: [],
      filterDate: this.$store.state.tms.tms.date,
      selectedDriver: this.$store.state.tms.tms.driver,
      optionalDriver: [],
      selectedVehicle: this.$store.state.tms.tms.vehicle,
      optionalVehicle: [],
      searchDriver: "",
    };
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [5, 10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    datePickerDateFormat(date) {
      let a = null;
      if (date != null) {
        a = moment(date).format("dddd, MMMM Do YYYY");
      }
      return a;
    },
    viewDetail(item) {
      this.dataViews = [];
      this.detail = true;
      this.dataViews = item;
    },

    addDeliveryOrder(index) {
      this.indexAddDO = index;
      this.showingData = true;
    },
    addDOToVehicle() {
      for (let index = 0; index < this.cartDONoVehicle.length; index++) {
        const selectedDO = this.cartDONoVehicle[index];

        // Add the selected DO to the appropriate vehicle's DeliveryOrders array
        this.table.data[this.indexAddDO].DeliveryOrders.push(selectedDO);

        this.table.data[this.indexAddDO].total_do += 1;
        this.table.data[this.indexAddDO].total_weight_do += selectedDO.weight;
        this.table.data[this.indexAddDO].total_volume_do += selectedDO.volume;

        // Remove the selected DO from the noVehicleRecords array
        const removeIndex = this.noVehicleRecords.findIndex(
          (item) => item.id === selectedDO.id
        );
        if (removeIndex !== -1) {
          this.noVehicleRecords.splice(removeIndex, 1);
        }
      }

      this.showingData = false;
    },
    deleteDeliveryOrder(item, index, idItem) {
      this.noVehicleRecords.push({
        delivery_order_code: item.delivery_order_code,
        id: item.id,
        volume: item.volume,
        warehouse_id: item.warehouse_id,
        weight: item.weight,
        assigned_to_vehicle: item.assigned_to_vehicle,
      });

      this.table.data[idItem].DeliveryOrders.splice(index, 1);
      this.table.data[idItem].total_do -= 1;
      this.table.data[idItem].total_weight_do -= item.weight;
      this.table.data[idItem].total_volume_do -= item.volume;
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 0;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    handleClose() {
      this.detail = false;
      this.dataViews = [];
    },
    onClickBtnFilter() {
      this.getData();
    },
    onClickBtnReset() {
      this.$store.commit("tms/tms/setDate", {
        startDate: moment().format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
      });
      this.$store.commit("tms/tms/setDriver", []);
      this.$store.commit("tms/tms/setVehicle", []);
      this.getData();
    },
    getData() {
      let driverName = [];
      let vehicleNumber = [];
      //append driver name from selectedDriver to driverName
      for (
        let index = 0;
        index < this.$store.state.tms.tms.driver.length;
        index++
      ) {
        driverName.push(this.$store.state.tms.tms.driver[index].driver_name);
      }
      //append vehicle number from selectedVehicle to vehicleNumber
      for (
        let index = 0;
        index < this.$store.state.tms.tms.vehicle.length;
        index++
      ) {
        vehicleNumber.push(
          this.$store.state.tms.tms.vehicle[index].vehicle_number
        );
      }

      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/transport-code/all-transport-plan", {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
            date_from: moment(this.$store.state.tms.tms.date.startDate).format(
              "YYYY-MM-DD"
            ),
            date_to: moment(this.$store.state.tms.tms.date.endDate).format(
              "YYYY-MM-DD"
            ),
            driver_name: driverName,
            vehicle_number: vehicleNumber,
          },
        })
        .then((resp) => {
          console.log("rrrrrrr", resp);
          if (resp.code == 200) {
            if (resp.data.records != null) {
              this.noVehicleRecords = [];
              this.table.total = resp.data.total_record;
              this.table.totalPage = resp.data.total_page;
              this.table.totalSearch = resp.data.total_record_search;
              this.table.length = resp.data.total_record_per_page;
              this.table.data = resp.data.records;
              for (let index = 0; index < resp.data.records.length; index++) {
                this.table.data[index]["indextr"] = index;
              }
              console.log("weee", this.table.data);

              this.setStartEnd();
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.totalPage - this.table.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      } else if (valStart < 1) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.totalPage;
      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      } else if (valEnd <= 1) {
        valEnd = 1;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search != ""
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    handleClickCheckBox() {
      console.log(">>>>iiii", this.cartDeliveryPlan);
      this.$emit("selected-items", this.cartDeliveryPlan);
    },
    handleSelectAll(id) {
      if (id == false) {
        this.exportID = [];
      } else {
        this.exportID = this.IDItem;
      }
      this.$emit("selected-items", this.IDItem);
    },
    selectItem(item) {
      let check = this.cartDeliveryPlan.find(
        (cartItem) => cartItem.deliveryPlanId === item.id
      );

      if (!check) {
        this.cartDeliveryPlan.push({
          warehouseId: item.warehouse_id,
          deliveryPlanId: item.id,
          deliveryPlanCode: item.delivery_plan_code,
          delvieryPlanDate: this.date,
          totalVolume: item.total_volume,
          totalWeight: item.total_weight,
          methodPlan: item.picking_method,
          zoneID: item.zone_id,
          deliveryOrder: item.delivery_order,
        });
      } else {
        this.cartDeliveryPlan = this.cartDeliveryPlan.filter(
          (cartItem) => cartItem.deliveryPlanId !== item.id
        );
      }
    },
    ExportForm() {
      var fileTitle = "TRANSPORT_PLAN_EXPORT_";
      // this.$vs.loading();
      this.$http
        .get("/api/wms/v1/transport-code/export", {
          responseType: "arraybuffer",
          params: {
            selectedExport: this.cartDeliveryPlan.join(", "),
          },
          headers: {
            Accept: "application/octet-stream",
          },
        })
        .then((resp) => {
          // console.log(resp);
          if (resp.status == "error") {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            var fileURL = window.URL.createObjectURL(new Blob([resp]));
            var fileLink = document.createElement("a");
            fileLink.href = fileURL;

            fileLink.setAttribute(
              "download",
              fileTitle.toUpperCase() + ".xlsx"
            );
            document.body.appendChild(fileLink);
            fileLink.click();
          }
          // this.$vs.loading.close();
        });
    },
    ReturnTransportPlan() {
      console.log(">>>>>ini submit", this.cartDeliveryPlan);
      if (this.cartDeliveryPlan.length == 0) {
        this.$vs.notify({
          title: "Error",
          text: "Please select Item Line first",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return true;
      }

      for (let index = 0; index < this.cartDeliveryPlan.length; index++) {
        for (let index2 = 0; index2 < this.table.data.length; index2++) {
          if (this.cartDeliveryPlan[index] == this.table.data[index2].id) {
            if (this.table.data[index2].status != "release") {
              this.$vs.notify({
                title: "Error",
                text: "Transport Plan is not in release status",
                color: "danger",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-check",
              });
              return true;
            }
          }
        }
      }

      this.$vs.loading();
      this.$http
        .post("/api/wms/v1/transport-code/return", {
          selected: this.cartDeliveryPlan.join(", "),
        })
        .then((resp) => {
          console.log(resp);
          if (resp.code == 200) {
            this.$vs.notify({
              title: "Success",
              text: "Transport Plan has been return",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            this.getData();
          } else {
            this.$vs.notify({
              title: "Error",
              text: resp.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }

          this.$vs.loading.close();
        });
    },
    HandlePrintPdf(item) {
      console.log("do print pdf");

      const doc = new jsPDF({ orientation: "l" });
      let fontSize = 25;
      const lineHeightFactor = 0;

      const addHeader = (th, tm) => {
        doc.setFontSize(fontSize, "bold");
        doc.text("Daftar Pengiriman by Driver ", 150, 20, {
          align: "center",
        });
        doc.addImage(
          require("@/assets/images/logo/logo-sdn.png"),
          "PNG",
          10,
          5,
          50,
          20
        );

        doc.lineHeightProportion = 1;

        let header1 = [];

        header1.push(
          [
            "Nama Driver",
            ": " + item.driver_name + " " + "(" + item.type_driver + ")",
            "Shipment Date",
            ": " + item.delivery_plan_date_str,
          ],
          [
            "No. Polisi Kendaraan",
            ": " + item.vehicle_number + " " + "(" + item.ownership + ")",
            "Shipment Plan",
            ": " + item.shipment_number,
          ]
        );
        if (item.vehicle_childs[0].transport_plan_id != 0) {
          for (let index = 0; index < item.vehicle_childs.length; index++) {
            header1.push([
              "No. Polisi Kendaraan Child " + (index + 1),
              ": " +
                item.vehicle_childs[index].vehicle_number +
                " " +
                "(" +
                item.vehicle_childs[index].ownership +
                ")",
              "Nama Driver Child " + (index + 1),
              ": " +
                item.vehicle_childs[index].driver_name +
                " " +
                "(" +
                item.vehicle_childs[index].type_driver +
                ")",
            ]);
          }
        }

        autoTable(doc, {
          startY: 36,
          head: [],
          body: header1,
          styles: { lineHeight: lineHeightFactor, cellPadding: 0 },
          margin: { right: 10, left: 10 },
          theme: "plain",
        });
      };

      // Add the header initially
      addHeader(doc, item);

      const tableHeader = [
        [
          {
            content: "Penyerahan Surat Jalan ",
            colSpan: 4,
            styles: { halign: "center" },
          },
          {
            content: "",
            colSpan: 4,
            styles: { halign: "center" },
          },
          { content: "Status", colSpan: 4, styles: { halign: "center" } },
        ],
        [
          { content: "No. ", styles: { halign: "center" } },
          { content: "Cust. ID", styles: { halign: "center" } },
          { content: "Cust. Name", styles: { halign: "center" } },
          { content: "Name Sales", styles: { halign: "center" } },
          { content: "PI No", styles: { halign: "center" } },
          { content: "DO date", styles: { halign: "center" } },
          { content: "TOP (IDR)", styles: { halign: "center" } },
          { content: "COD (IDR)", styles: { halign: "center" } },
          {
            content: "Nilai Setelah Pengiriman (COD)",
            styles: { halign: "center" },
          },
          { content: "SBT", styles: { halign: "center" } },
          { content: "P", styles: { halign: "center" } },
          { content: "Keterangan", styles: { halign: "center" } },
        ],
      ];

      let line = [];
      let total = 0;
      let totalTop = 0;
      let totalCOD = 0;

      item.transport_plan_lines.map((v, i) => {
        let roundValue = Math.round(v.total * 100) / 100;
        total += roundValue;

        let top = 0;
        if (v.payment_term_code != "D000") {
          top = roundValue;
          v.total = 0;
          totalTop += top;
        } else {
          top = 0;
          v.total = roundValue;
          totalCOD += v.total;
        }

        line.push([
          i + 1,
          v.customer_code,
          v.customer_name,
          v.sales_name,
          v.delivery_order_code,
          v.date,
          Intl.NumberFormat("en", { minimumFractionDigits: 2 }).format(top),
          Intl.NumberFormat("en", { minimumFractionDigits: 2 }).format(v.total),
          "",
          "",
          "",
          "",
        ]);
      });

      line.push(
        [
          { content: "Total", colSpan: 6, styles: { halign: "left" } },
          {
            content: Intl.NumberFormat("en", {
              minimumFractionDigits: 2,
            }).format(totalTop),
            styles: { halign: "center" },
          },
          {
            content: Intl.NumberFormat("en", {
              minimumFractionDigits: 2,
            }).format(totalCOD),
            styles: { halign: "center" },
          },
          { content: "", colSpan: 4, styles: { halign: "center" } },
        ],
        [
          { content: "", colSpan: 6, styles: { halign: "center" } },
          {
            content: Intl.NumberFormat("en-US", {
              minimumFractionDigits: 2,
            }).format(total),
            colSpan: 2,
            styles: { halign: "center" },
          },
          { content: "", colSpan: 4, styles: { halign: "center" } },
        ]
      );

      autoTable(doc, {
        head: tableHeader,
        headStyles: {
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          theme: "grid",
          lineColor: [0, 0, 0],
          cellWidth: "auto",
          lineWidth: 0.1,
        },
        body: line,
        margin: { right: 10, left: 10 },
        theme: "grid",
        // tableLineWidth: 0.2,
        // tableLineColor: [0, 0, 0],
        styles: {
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          // theme: "grid",
          // lineColor: [0, 0, 0],
          // lineWidth: 0.1,
          halign: "center",
          lineHeight: lineHeightFactor,
        },
        columnStyles: {
          4: { cellWidth: 20 },
          6: { cellWidth: 25 },
          7: { cellWidth: 25 },
          8: { cellWidth: 27 },
          9: { cellWidth: 12 },
          10: { cellWidth: 12 },
        },
        drawCell: function (cell, data) {
          // Set border only for header cells
          if (data.row.index === 0) {
            cell.styles.lineWidth = 0.1;
          }
        },
        didDrawPage: function (data) {
          addHeader();

          data.settings.margin.top = 50;
        },
      });

      let footer = [];
      let footer1 = [];
      footer.push(
        ["Shipment Cost: "],
        ["Telah menerima Shipment Cost: Yes  No"],
        ["Telah melakukan realisasi Shipment Cost: Yes  No"],
        [""],
        ["SBT : Surat Barang Tolakan"],
        ["P   : Pending"]
      );
      doc.lineHeightProportion = lineHeightFactor;
      autoTable(doc, {
        head: [],
        body: footer,
        margin: { right: 10, left: 10 },
        pageBreak: "avoid",
        // tableLineWidth: 0.2,
        theme: "plain",
        styles: {
          halign: "left",
          fontSize: "10",
          cellWidth: 100,
          lineHeight: lineHeightFactor,
          cellPadding: 0,
        },

        columnStyles: {
          0: { halign: "left", fontSize: "10", cellWidth: 100 },
          1: { halign: "left", fontSize: "10", cellWidth: 100 },
          2: { halign: "left", fontSize: "10", cellWidth: 100 },
          3: { halign: "left", fontSize: "10", cellWidth: 100 },
          4: { halign: "left", fontSize: "10", cellWidth: 100 },
          5: { halign: "left", fontSize: "10", cellWidth: 100 },
        },
      });

      footer1.push(
        [
          "Dibuat Oleh,",
          "Diterima Oleh,",
          "Diserahkan Oleh,",
          "Diterima Oleh,",
          "Diperiksa/Disetujui Oleh,",
          "",
        ],
        ["", "", ""],
        ["", "", ""],
        ["", "", ""],
        [
          "Admin Traffic:                  ",
          "Driver:                         ",
          "Driver:                         ",
          "Cashier:                        ",
          "Admin Traffic:                  ",
          "BLC/OS:                         ",
        ],
        [
          "Tanggal:                        ",
          "Tanggal:                        ",
          "Tanggal:                        ",
          "Tanggal:                        ",
          "Tanggal:                        ",
          "Tanggal:                        ",
        ]
      );

      autoTable(doc, {
        head: [],
        body: footer1,
        margin: { right: 10, left: 10 },
        pageBreak: "avoid",
        theme: "plain",
        tableLineWidth: 0.2,
        columnStyles: {
          0: { halign: "center", fontSize: "9", cellWidth: 50 },
          1: { halign: "center", fontSize: "9", cellWidth: 50 },
          2: { halign: "center", fontSize: "9", cellWidth: 50 },
          3: { halign: "center", fontSize: "9", cellWidth: 50 },
          4: { halign: "center", fontSize: "9", cellWidth: 50 },
        },
        styles: {
          halign: "center",
          fontSize: "9",
          cellWidth: 50,
          lineHeight: lineHeightFactor,
        },
      });

      const pageCount = doc.internal.getNumberOfPages();
      for (var j = 1; j <= pageCount; j++) {
        doc.setPage(j);
        doc.setFontSize(10);
        doc.text(
          "Halaman " + String(j) + " dari " + String(pageCount),
          300 - 20,
          230 - 25,
          null,
          null,
          "right"
        );
      }

      doc.save("Daftar Pengiriman-" + item.shipment_number + ".pdf");
    },
    getVehicle() {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/transport-code/all-vehicle-release", {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.searchVehicle,
            order: this.table.order,
            sort: this.table.sort,
            date_from: moment(this.$store.state.tms.tms.date.startDate).format(
              "YYYY-MM-DD"
            ),
            date_to: moment(this.$store.state.tms.tms.date.endDate).format(
              "YYYY-MM-DD"
            ),
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionalVehicle = resp.data.records;
              if (this.optionalVehicle.length > 0) {
                // this.selectedVehicle = null;
                //check if vehicle number was duplicate then remove duplicate
                this.optionalVehicle = this.optionalVehicle.filter(
                  (v, i, a) =>
                    a.findIndex(
                      (t) => t.vehicle_number === v.vehicle_number
                    ) === i
                );
              } else {
                this.optionalVehicle = [];
                this.selectedVehicle = [];
              }
            } else {
              this.optionalVehicle = [];
              this.selectedVehicle = [];
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    handleSearchVehicle(query) {
      if (query != "") {
        this.table.searchVehicle = query;

        this.getVehicle();
      }
    },
    getDriver() {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/transport-code/all-vehicle-release", {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.searchDriver,
            order: this.table.order,
            sort: this.table.sort,
            date_from: moment(this.$store.state.tms.tms.date.startDate).format(
              "YYYY-MM-DD"
            ),
            date_to: moment(this.$store.state.tms.tms.date.endDate).format(
              "YYYY-MM-DD"
            ),
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionalDriver = resp.data.records;

              if (this.optionalDriver.length > 0) {
                // this.selectedDriver = null;
                console.log(">>>>", this.optionalDriver);
              } else {
                this.optionalDriver = [];
                // this.selectedDriver = {};
              }
            } else {
              this.optionalDriver = [];
              this.selectedDriver = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    handleSearchDriver(query) {
      if (query != "") {
        this.searchDriver = query;

        this.getDriver();
      }
    },
  },

  watch: {
    detail() {
      this.getData();
    },
    selected() {
      console.log(">>>>selected", this.selected);
    },
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
    sortedVehicleData() {
      // Pisahkan vehicle dengan delivery order dan yang tanpa delivery order
      const vehiclesWithDO = this.table.data.filter(
        (item) => item.DeliveryOrders.length > 0
      );
      const vehiclesWithoutDO = this.table.data.filter(
        (item) => item.DeliveryOrders.length === 0
      );

      // Gabungkan kembali dengan urutan yang diinginkan
      return [...vehiclesWithDO, ...vehiclesWithoutDO];
    },
    date: {
      get() {
        return this.$store.state.tms.tms.date;
      },
      set(val) {
        this.$store.commit("tms/tms/setDate", val);
      },
    },
    drivers: {
      get() {
        return this.$store.state.tms.tms.driver;
      },
      set(val) {
        console.log(">>>>driver", val);
        this.$store.commit("tms/tms/setDriver", val);
      },
    },
    vehicles: {
      get() {
        return this.$store.state.tms.tms.vehicle;
      },
      set(val) {
        this.$store.dispatch("tms/tms/updateVehicle", val);
      },
    },
    selectedData: {
      get() {
        return this.$store.state.tms.tms.selected;
      },
      set(val) {
        console.log(">>>>selected", val);
        this.$store.commit("tms/tms/setSelected", val);
      },
    },
  },
  filters: {
    formatDate(val) {
      return moment(val)
        .add(-7, "h")
        .utc()
        .local()
        .format("DD MMM YYYY HH:mm:ss");
    },
  },
};
</script>

<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
</style>
